import React, { useState } from 'react';
import "./barreHistorique.css";

const Historique = ({searchTerm, handleSearchChange}) => {
  return (
    <div id="hi-recherche">
      <input
        className="hi-barrerecherche"
        type="text"
        placeholder="Rechercher dans l'historique"
        value={searchTerm}
        onChange={handleSearchChange}
        size="89"
      />
      <button type="submit" className="search-button" onSubmit={handleSearchChange}>
          Recherche
      </button>
    </div>
  );
};

export default Historique;

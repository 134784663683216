import React from "react";
import "./popupVerif.css";

const Popup = ({ onDelete, onClose, deleteUser, user }) => {
  return (
    <div className="popup-container">
      <div className="popup-content">
        <h3>Confirmer la suppression</h3>
        <p className="areyousure">Êtes-vous sûr de vouloir supprimer cet utilisateur ?</p>
        <div className="popup-buttons">
            <button onClick={() => { onDelete(); deleteUser(user.email); }}>Supprimer</button>
            <button onClick={onClose}>Fermer</button>
        </div>
      </div>
    </div>
  );
};

export default Popup;

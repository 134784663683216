import { faBookmark as faStarVide } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowLeft,
  faArrowRight,
  faBookmark,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "./historiqueContent.css";

const HistoriqueContent = ({
  displayHisto,
  searchFav,
  FavHisto,
  highlightSearchTerm,
  currentFavUser,
  getCurrentFavUser,
  menuStateHisto,
  deleteRecherche,
  launchSearchFromHistory,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  const reversedDisplayHisto = [...displayHisto].reverse();

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    menuStateHisto === 1
      ? reversedDisplayHisto
          .filter((recherche) => currentFavUser.includes(recherche.id))
          .slice(indexOfFirstItem, indexOfLastItem)
      : reversedDisplayHisto.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(
    (menuStateHisto === 1 ? currentFavUser.length : displayHisto.length) /
      itemsPerPage,
  );

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const toggleFavHisto = async (e, rechercheID) => {
    e.stopPropagation();
    await FavHisto(rechercheID);
    await getCurrentFavUser();
  };

  const truncateCritere = (critere) => {
    const maxLength = 45;
    if (critere?.length > maxLength) {
      return critere.substring(0, maxLength) + "...";
    }
    return critere;
  };

  const extractNameFromEmail = (email) => {
    const parts = email.split("@")[0].split(".");
    const firstName = parts[0];
    const lastNameInitial = parts.length > 1 ? parts[1][0] : "";
    return `${firstName} .${lastNameInitial}`;
  };

  return (
    <div id="historique">
      <div id="hi-entete">
        <label htmlFor="date">DATE</label>
        <label htmlFor="profil">PROFIL</label>
        <label htmlFor="critère">CRITÈRES DE RECHERCHE</label>
        <span className="spe-fav-icon">
          <FontAwesomeIcon icon={faBookmark} />
        </span>
      </div>
      <div id="hi-item">
        <div id="hi-colonne">
          {currentItems.map((recherche, index) => (
            <div key={index} className="recherche-item">
              <p className="date-r">{recherche.date_recherche}</p>
              <p className="nom-r">
                {extractNameFromEmail(recherche.nom_recherche)}
              </p>
              <p
                className="critere-r"
                onClick={() =>
                  launchSearchFromHistory(recherche.critere_recherche)
                }
              >
                {highlightSearchTerm(
                  truncateCritere(recherche.critere_recherche),
                )}
              </p>
              <div className="button-container">
                <FontAwesomeIcon
                  onClick={(e) => toggleFavHisto(e, recherche.id)}
                  icon={
                    searchFav.includes(recherche.id) ? faBookmark : faStarVide
                  }
                  className="b-icone-fav"
                />
                <div
                  onClick={() => deleteRecherche(recherche.id)}
                  className="delete-btn"
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="icon-delete-search"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div id="pagination">
        <span
          className="previous-page"
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </span>
        <label htmlFor="pageSelect">Page :</label>
        <select
          id="pageSelect"
          value={currentPage}
          onChange={(e) => handlePageChange(Number(e.target.value))}
        >
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <option key={page} value={page}>
              {page}/{totalPages}
            </option>
          ))}
        </select>
        <span
          className="next-page"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </span>
      </div>
    </div>
  );
};

export default HistoriqueContent;

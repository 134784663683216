import { useEffect, useRef, useState } from "react";
import Header from "../header/Header";
import Tabs from "../tabs/tabs";
import BarreRecherche from "../barreRecherche/barreRecherche";
import ItemCv from "../item-cv/ItemCv";
import { useResizable } from "react-resizable-layout";
import "./main.css";
import spinner from "../../spinner.svg";
import Navigation from "../navigation/Navigation";
import Splitter from "../splitter/Splitter";
import CVContent from "../cv-content/CVContent";
import BarreUser from "../barreUsers/BarreUser";
import UserContent from "../userContent/UserContent";
import HistoriqueContent from "../historiqueContent/HistoriqueContent";
import TabHistorique from "../tabHistorique/TabHistorique";
import BarreHistorique from "../barreHistorique/BarreHistorique";
import MonitoringPage from "../monitoringPage/MonitoringPage";

const Main2 = ({ token, setToken, user }) => {
  const SIZE = 20;
  const caracteresNecessitantUnEchappement = ["?", "\\"];
  const handleLogout = () => {
    localStorage.setItem("token", null);
    setToken(null);
  };
  const isAuthorized = user?.role;
  const listeCvsRef = useRef();
  const [menuState, setMenuState] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const handleMenuChange = (selectedValue) => {
    if (selectedValue === "historique") {
      setMenuState(1);
    } else if (selectedValue === "utilisateurs") {
      setMenuState(2);
    } else if (selectedValue === "monitoring") {
      setMenuState(3);
    } else {
      setMenuState(0);
    }
  };

  const handleMenuHistoChange = (selectedValue) => {
    if (selectedValue === 1) {
      setMenuStateHisto(1);
    } else {
      setMenuStateHisto(0);
    }
  };
  const [menuStateHisto, setMenuStateHisto] = useState(0);
  const [searchFav, setSearchFav] = useState([]);
  const [page, setPage] = useState(1);
  const [currentTab, setCurrentTab] = useState(0);
  const [filtersQuery, setFiltersQuery] = useState("?");

  const [counters, setCounters] = useState({
    totalResumes: 0,
    totalDisplayedResumes: 0,
    totalTeamSavedResumes: 0,
    totalSavedResumes: 0,
    totalGlobalResumes: 0,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedResume, setSelectedResume] = useState(null);

  const [savedResumesIds, setSavedResumesId] = useState([]);
  const [readResumes, setReadResumes] = useState([]);

  const [teamSavedResumes, setTeamSavedResumes] = useState([]);

  const [recherchesHisto, setRechercheHisto] = useState([]);
  const [displayHisto, setDisplayHisto] = useState([]);

  const [valSearch, setValSearch] = useState("");

  const [criteria, setCriteria] = useState("");
  const [searchState, setSearchState] = useState({
    // Utilisé pour la recherche de CV
    loading: false,
    error: false,
    liste: [],
    key_words: [],
  });

  //prime react
  const [currentFavUser, setCurrentFavUser] = useState([]);
  const [displayedResumes, setDisplayedResumes] = useState([]);
  const [searchUsers, setSearchUsers] = useState("");

  const handleSearchUsers = (e) => {
    setSearchUsers(e.target.value);
  };

  const customFetch = async (url, method) => {
    const res = await fetch(url, {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const response = await res.json();
    return response;
  };

  const [users, setUsers] = useState([]);

  // INITIALS FETCHING
  useEffect(() => {
    fetchReadResumes();
    fetchSavedResumesIds();
    getRecherchesHisto();
    getTeamSavedResumes();
    fetchTeamSavedResumesIds();
    getCurrentFavUser();
    fetchUsers();
  }, []);

  const filteredUsers = users.filter((user) =>
    user.email.toLowerCase().includes(searchUsers.toLowerCase()),
  );

  useEffect(() => {
    listeCvsRef.current.scrollTo(0, 0);
    if (!valSearch.length && currentTab == 0) {
      getResumes();
      setSearchState({
        loading: false,
        error: false,
        liste: [],
        key_words: [],
      });
    } else if (!valSearch.length && currentTab == 1) {
      fetchSavedResumes();
      setSearchState({
        loading: false,
        error: false,
        liste: [],
        key_words: [],
      });
    } else if (!valSearch.length && currentTab == 2) {
      getTeamSavedResumes();
      setSearchState({
        loading: false,
        error: false,
        liste: [],
        key_words: [],
      });
    }
  }, [page, valSearch, currentTab, filtersQuery]);

  useEffect(() => {
    if (!user) {
      setToken(null);
    }
  }, [user]);

  const fetchUsers = async () => {
    const response = await customFetch("/api/users/", "GET");
    setUsers(response);
  };

  const getResumes = async () => {
    setIsLoading(true);
    const resumes = await customFetch(
      `/api/resume${filtersQuery}&page=${page}&size=${SIZE}`,
      "GET",
    );
    setDisplayedResumes(resumes.items);
    setCounters((prev) => ({
      ...prev,
      totalResumes: prev.totalResumes ? prev.totalResumes : resumes.total,
      totalGlobalResumes: resumes.total,
      totalDisplayedResumes: resumes.total,
    }));
    setPage(resumes.page);
    setIsLoading(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    if (searchTerm.length !== 1) {
      let filteredRecherches = recherchesHisto.filter((recherche) =>
        recherche.critere_recherche
          .toLowerCase()
          .includes(searchTerm.toLowerCase()),
      );
      setDisplayHisto(filteredRecherches);
    } else {
      setDisplayHisto(recherchesHisto);
    }
  };

  const getRecherchesHisto = async () => {
    const response = await customFetch(`/api/recherches`, "GET");
    setRechercheHisto(response);
    setDisplayHisto(response);
  };

  const fetchReadResumes = async () => {
    const response = await customFetch(
      `/api/users/${user.email}/cvs_read`,
      "GET",
    );
    setReadResumes(response);
  };

  const fetchSavedResumesIds = async () => {
    const response = await customFetch(
      `api/users/${user.email}/saved_cvs`,
      "GET",
    );
    setCounters((prev) => ({ ...prev, totalSavedResumes: response.length }));
    setSavedResumesId(response);
  };

  const fetchSavedResumes = async () => {
    setIsLoading(true);
    const response = await customFetch(
      `api/users/${user.email}/cvs${filtersQuery}&size=${SIZE}&page=${page}`,
      "GET",
    );
    setDisplayedResumes(response.items);
    setCounters((prev) => ({
      ...prev,
      totalSavedResumes: response.total,
      totalDisplayedResumes: response.total,
    }));
    setIsLoading(false);
  };

  const getTeamSavedResumes = async () => {
    setIsLoading(true);
    const response = await customFetch(
      `/api/users/${user.email}/team-cvs${filtersQuery}&size=${SIZE}&page=${page}`,
      "GET",
    );

    setDisplayedResumes(response.items);
    setCounters((prev) => ({
      ...prev,
      totalDisplayedResumes: response.total,
      totalTeamSavedResumes: response.total,
    }));
    setIsLoading(false);
  };

  const fetchTeamSavedResumesIds = async () => {
    setIsLoading(true);
    const response = await customFetch(
      `/api/users/${user.email}/team-cvs-id`,
      "GET",
    );
    setTeamSavedResumes(response.team_cvs);
    setCounters((prev) => ({
      ...prev,
      totalTeamSavedResumes:
        response.my_cvs.length + Object.keys(response.team_cvs).length,
    }));
    setIsLoading(false);
  };

  const getCurrentFavUser = async () => {
    const response = await customFetch(
      `/api/historique/me/favoris/${user.email}`,
      "GET",
    );
    setCurrentFavUser(response.favoris);
    setSearchFav(response.favoris);
  };

  const favHisto = async (recherche_id) => {
    const response = await customFetch(
      `/api/historique/me/${user.email}/${recherche_id}`,
      "POST",
    );
    setSearchFav((prev) => {
      if (prev.includes(recherche_id)) {
        return prev.filter((id) => id !== recherche_id);
      } else {
        return [...prev, recherche_id];
      }
    });

    getCurrentFavUser();
  };

  const tabChanged = (i) => {
    if (currentTab === i) return;
    setPage(1);
    setCurrentTab(i);
    search(i);
    listeCvsRef.current.scrollTo(0, 0);
  };

  const deleteResume = async (id) => {
    const response = await customFetch(`/api/cvs/${id}`, "DELETE");
  };

  const setCvSaved = async (id, saved) => {
    let updatedSavedCvs;

    if (saved) {
      updatedSavedCvs = [...savedResumesIds, id];
    } else {
      updatedSavedCvs = savedResumesIds.filter((currentId) => currentId !== id);
    }
    setSavedResumesId(updatedSavedCvs);

    const url = saved
      ? `/api/users/${user.email}/save-cv/${id}`
      : `/api/users/${user.email}/unsave-cv/${id}`;

    const response = await customFetch(url, "POST");
    if (response.liste) {
      setSavedResumesId(response.liste);
      fetchSavedResumesIds();
      fetchTeamSavedResumesIds();
    }
  };

  const setCvRead = async (id, read) => {
    if (!read) {
      const updatedReadCvs = [...readResumes, id];
      setReadResumes(updatedReadCvs);

      const response = await customFetch(
        `/api/users/${user.email}/read-cv/${id}`,
        "POST",
      );
      if (response.liste) {
        setReadResumes(response.liste);
      }
    }
  };

  const highlightSearchTerm = (text) => {
    if (!searchTerm || !text) {
      return text;
    }
    const regex = new RegExp(`(${searchTerm})`, "gi");
    const parts = text.split(regex);
    // Applique le surlignage uniquement aux parties qui correspondent à la recherche
    const highlightedText = parts.map((part, index) => {
      if (index % 2 === 0) {
        // Les parties d'index pair ne correspondent pas à la recherche
        return part;
      } else {
        // Les parties d'index impair correspondent à la recherche -> les surligne
        return (
          <span className="highlight-recherche" key={index}>
            {part}
          </span>
        );
      }
    });
    return highlightedText;
  };

  const onChangeValSearch = (event) => {
    setValSearch(event.target.value);
  };

  useEffect(() => {
    if (valSearch.length && filtersQuery.length) {
      search();
    }
  }, [filtersQuery, page]);

  const search = async (tab = currentTab) => {
    setIsLoading(true);
    // Uniquement appelé lors de l'écriture dans la barre de rech.
    if (valSearch.length === 0) {
      setPage((prev) => 1);
      return;
    }
    const tabs = ["ALL", "CVS", "TEAM-CVS"];
    const tabStr = tabs[tab];
    const response = await customFetch(
      `/api/search/${user.email}/${filtersQuery}&query=${valSearch}&size=${SIZE}&page=${page}&tab=${tabStr}`,
      "GET",
    );

    if (response.key_words) {
      response.key_words = response.key_words.map((kw) => {
        caracteresNecessitantUnEchappement.forEach((c) => {
          kw = kw.replaceAll(`\\${c}`, `${c}`);
        });
        return kw;
      });
    }
    setSearchState({
      ...searchState,
      key_words: response.key_words ? response.key_words : [],
    });
    // setPage(1);
    getRecherchesHisto();
    setDisplayedResumes(response.results.items);
    setCounters((prev) => ({
      ...prev,
      totalGlobalResumes:
        tabStr === "ALL" ? response.total : prev.totalGlobalResumes,
      totalSavedResumes:
        tabStr === "CVS" ? response.total : prev.totalSavedResumes,
      totalTeamSavedResumes:
        tabStr === "TEAM-CVS" ? response.total : prev.totalTeamSavedResumes,
      totalDisplayedResumes: response.total,
    }));
    setIsLoading(false);
  };

  const {
    isDragging,
    position: posX,
    splitterProps,
  } = useResizable({
    axis: "x",
    initial: Math.floor(window.innerWidth * (1 / 3)),
    min: 200,
    max: window.innerWidth - 200,
    reverse: true,
  });

  const deleteRecherche = async (rechercheID) => {
    const response = await customFetch(
      `/api/recherches/${rechercheID}`,
      "DELETE",
    );
    await getRecherchesHisto();
    setSearchFav((prev) => prev.filter((p) => p !== rechercheID));
  };

  const deleteUser = async (email) => {
    const response = await customFetch(`/api/users/${email}`, "DELETE");
    setUsers((prev) => prev.filter((p) => p.email !== email));
  };

  const [filters, setFilters] = useState({
    status: [],
    location: [],
    date: [],
    source: [],
    unread: false,
  });

  useEffect(() => {
    let query = "?";
    if (filters.status.length > 0) {
      query =
        query +
        `&status=${filters.status.map((stat) => {
          return stat.code;
        })}`;
    }
    if (filters.location.length > 0) {
      query =
        query +
        `&location=${filters.location.map((stat) => {
          return stat.code;
        })}`;
    }
    if (filters.date.length > 0) {
      query =
        query +
        `&date=${filters.date.map((stat) => {
          return stat.code;
        })}`;
    }
    if (filters.source.length > 0) {
      query =
        query +
        `&source=${filters.source.map((stat) => {
          return stat.code;
        })}`;
    }
    if (filters.unread) {
      query = query + `&unread=true`;
    }
    setFiltersQuery(query);
  }, [filters, currentTab]);

  useEffect(() => {
    console.log(filters);
  }, [filters]);

  return (
    <div className="has-text-centered m-6">
      {token && (
        <div id="colonne-page">
          {menuState === 0 && (
            <>
              <Header
                isAuthorized={isAuthorized}
                totalCvs={counters.totalResumes}
                nbCvsTotal={counters.totalDisplayedResumes}
                userName={user?.name}
                handleLogout={handleLogout}
                closeCv={() => setSelectedResume(null)}
                menuState={menuState}
                handleMenuChange={handleMenuChange}
              />
              <Tabs
                tabChanged={tabChanged}
                nbCvsTotal={counters.totalGlobalResumes}
                nbSavedCvs={counters.totalSavedResumes}
                nbGroupSavedCvs={counters.totalTeamSavedResumes}
              />
              <BarreRecherche
                search={search}
                onChangeValSearch={onChangeValSearch}
                resetFilters={() => {
                  setFilters({
                    status: [],
                    location: [],
                    date: [],
                    source: [],
                    read: false,
                  });
                }}
                selectedType={filters.status}
                selectedLocation={filters.location}
                selectedDate={filters.date}
                selectedSource={filters.source}
                onChangeApplicationType2={(e) =>
                  setFilters((prev) => ({ ...prev, status: e.target.value }))
                }
                onChangeApplicationLocation2={(e) =>
                  setFilters((prev) => ({ ...prev, location: e.target.value }))
                }
                onChangeApplicationDate2={(e) => {
                  const selectedValues = e.value || [];
                  const newValue =
                    selectedValues.length > 0
                      ? [selectedValues[selectedValues.length - 1]]
                      : [];
                  setFilters((prev) => ({ ...prev, date: newValue }));
                }}
                onChangeApplicationSource2={(e) =>
                  setFilters((prev) => ({ ...prev, source: e.target.value }))
                }
                readItem={filters.unread}
                onChangeReadItem={() => {
                  setFilters((prev) => ({ ...prev, unread: !prev.unread }));
                }}
                recherchesHisto={recherchesHisto}
                criteria={criteria}
              />
              <div id="liste-cvs" ref={listeCvsRef}>
                {displayedResumes.length && !isLoading ? (
                  displayedResumes.map((resume) => {
                    return (
                      <ItemCv
                        key={resume.id}
                        props={{
                          ...resume,
                          key_words: searchState.key_words,
                          onClickCV: (id) => setSelectedResume(id),
                          selected: resume.id === selectedResume?.id,
                          saved: savedResumesIds.includes(resume.id),
                          read: readResumes.includes(resume.id),
                          setCvRead,
                          setCvSaved,
                          deleteCv: deleteResume,
                          users: teamSavedResumes[resume.id],
                          token: token,
                        }}
                      />
                    );
                  })
                ) : isLoading ? (
                  <div>
                    <img
                      style={{ margin: "auto" }}
                      src={spinner}
                      alt="icône de chargement"
                    />
                  </div>
                ) : (
                  <h2 id="message-aucun-cv">
                    Aucun Cv ne correspond à la recherche.
                  </h2>
                )}
              </div>
              <Navigation
                page={page}
                currentTotalCvs={counters.totalDisplayedResumes}
                maxPage={Math.max(
                  1,
                  Math.ceil(counters.totalDisplayedResumes / SIZE),
                )}
                loadNextPage={() => {
                  setPage((prev) => prev + 1);
                }}
                loadPreviousPage={() => {
                  // page > 1 ? setPage((prev) => prev - 1) : null;
                  if (page > 1) {
                    setPage((prev) => prev - 1);
                    return;
                  } else {
                    return null;
                  }
                }}
                loadLastPage={() =>
                  setPage(
                    Math.max(
                      1,
                      Math.ceil(counters.totalDisplayedResumes / SIZE),
                    ),
                  )
                }
                loadFirstPage={() => {
                  setPage(1);
                }}
                setWishPage={(wish) => {
                  setPage(wish);
                }}
              />
              {selectedResume && (
                <>
                  <Splitter isDragging={isDragging} {...splitterProps} />
                  <CVContent
                    key={`${selectedResume.id}_${selectedResume.saved}`}
                    id={selectedResume.id}
                    first_name={selectedResume.first_name}
                    last_name={selectedResume.last_name}
                    date_created={selectedResume.date_created}
                    job={selectedResume.job}
                    email={selectedResume.email}
                    phone_number={selectedResume.phone_number}
                    path={selectedResume.path_pdf}
                    key_words={selectedResume.key_words}
                    width={posX}
                    saved={selectedResume.saved}
                    setCvSaved={selectedResume.setCvSaved}
                    deleteCv={deleteResume}
                    closeCv={() => setSelectedResume(null)}
                    token={token}
                  />
                </>
              )}
            </>
          )}
          {menuState === 1 && (
            <>
              <Header
                isAuthorized={isAuthorized}
                totalCvs={30}
                nbCvsTotal={counters.totalDisplayedResumes}
                userName={user?.name}
                handleLogout={handleLogout}
                closeCv={() => setSelectedResume(null)}
                menuState={menuState}
                handleMenuChange={handleMenuChange}
              />
              <TabHistorique
                handleMenuHistoChange={handleMenuHistoChange}
                menuStateHisto={menuStateHisto}
              />
              <BarreHistorique
                searchTerm={searchTerm}
                handleSearchChange={handleSearchChange}
              />
              <HistoriqueContent
                highlightSearchTerm={highlightSearchTerm}
                displayHisto={displayHisto}
                searchFav={searchFav}
                FavHisto={favHisto}
                currentFavUser={currentFavUser}
                getCurrentFavUser={getCurrentFavUser}
                menuStateHisto={menuStateHisto}
                deleteRecherche={deleteRecherche}
                search={search}
                handleMenuChange={handleMenuChange}
                launchSearchFromHistory={(criteria) => {
                  search(criteria);
                  setCriteria(criteria);
                  handleMenuChange("");
                }}
              />
            </>
          )}
          {menuState === 2 && (
            <>
              <Header
                isAuthorized={isAuthorized}
                totalCvs={30}
                nbCvsTotal={8}
                currentTotalCvs={10}
                userName={user?.name}
                handleLogout={handleLogout}
                closeCv={() => setSelectedResume(null)}
                menuState={menuState}
                handleMenuChange={handleMenuChange}
              />
              <BarreUser
                isAuthorized={isAuthorized}
                searchUsers={searchUsers}
                handleSearchUsers={handleSearchUsers}
                token={token}
              />
              <UserContent
                deleteUser={deleteUser}
                isAuthorized={isAuthorized}
                filteredUsers={filteredUsers}
              />
            </>
          )}
          {menuState === 3 && (
            <>
              <Header
                isAuthorized={isAuthorized}
                totalCvs={30}
                nbCvsTotal={8}
                currentTotalCvs={10}
                userName={user?.name}
                handleLogout={handleLogout}
                closeCv={() => setSelectedResume(null)}
                menuState={menuState}
                handleMenuChange={handleMenuChange}
              />
              <MonitoringPage users={users} token={token} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Main2;

import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "./header.css";
import logo from "./logo-Visian 2.png";

const Header = ({
  menuState,
  totalCvs,
  nbCvsTotal,
  userName,
  handleLogout,
  closeCv,
  handleMenuChange,
  isAuthorized,
}) => {
  const [isMenuVisible, setMenuVisibility] = useState(false);
  const [isLogoutPopupVisible, setLogoutPopupVisibility] = useState(false);

  const toggleMenuVisibility = () => {
    setMenuVisibility(!isMenuVisible);
    setLogoutPopupVisibility(false);
  };
  const toggleLogoutPopupVisibility = () => {
    setLogoutPopupVisibility(!isLogoutPopupVisible);
    setMenuVisibility(false);
  };
  return (
    <div id="header">
      <div id="container-lgv">
        <img
          id="logo-visian"
          src={logo}
          alt="logo visian"
          onClick={() => {
            closeCv();
            handleMenuChange(0);
          }}
        />
      </div>

      {menuState === 1 ? (
        <div id="nombre-cvs">Historique des recherches CV</div>
      ) : menuState == 2 ? (
        <div id="nombre-cvs">Gestion des utilisateurs</div>
      ) : menuState == 3 ? (
        <div id="nombre-cvs">Monitoring Page</div>
      ) : (
        <div id="nombre-cvs">
          Candidats ({nbCvsTotal}/{totalCvs})
        </div>
      )}
      <div id="haut-droite">
        <div id="menu">
          <label htmlFor="menu-deroulant" onClick={toggleMenuVisibility}>
            <FontAwesomeIcon
              className="iconMenuD"
              icon={faBars}
              style={{ color: "#4791ff" }}
            />
          </label>
          {isMenuVisible && (
            <div id="menu-options">
              <div
                className={`menu-option ${menuState === 0 ? "selected" : ""}`}
                onClick={() => handleMenuChange("recherche")}
              >
                CVTech
              </div>
              <div
                className={`menu-option ${menuState === 1 ? "selected" : ""}`}
                onClick={() => handleMenuChange("historique")}
              >
                Historique
              </div>
              <div
                className={`menu-option ${menuState === 2 ? "selected" : ""}`}
                onClick={() => handleMenuChange("utilisateurs")}
              >
                Utilisateurs
              </div>

              {isAuthorized && ( // Vérification de l'autorisation ici
                <div
                  className={`menu-option ${menuState === 3 ? "selected" : ""}`}
                  onClick={() => handleMenuChange("monitoring")}
                >
                  Console
                </div>
              )}
            </div>
          )}
        </div>
        <div id="username" onClick={toggleLogoutPopupVisibility}>
          <div id="_username">{userName}</div>
          {isLogoutPopupVisible && (
            <div id="logout-popup">
              <div className="logout-option" onClick={handleLogout}>
                Déconnexion
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;

import moment from "moment";
import VisuCvBouton from "../visu-cv-bouton/VisuCvBouton";
import Tag from "../tag/tag";
import "./itemCv.css";
import { cn } from "../../utils/utils";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { faBookmark as faStarVide } from "@fortawesome/free-regular-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { faPhone, faAt } from "@fortawesome/free-solid-svg-icons";

export default function ItemCv({
  _,
  props: {
    id,
    job,
    date_created,
    first_name,
    last_name,
    email,
    phone_number,
    path_pdf,
    key_words,
    content,
    onClickCV,
    selected,
    saved,
    read,
    setCvSaved,
    setCvRead,
    deleteCv,
    application_type,
    application_location,
    source,
    users,
    token,
  },
}) {
  const onClickItem = () => {
    onClickCV({
      id,
      job,
      date_created,
      first_name,
      last_name,
      email,
      phone_number,
      path_pdf,
      key_words,
      saved,
      setCvSaved,
      read,
      setCvRead,
    });
    setCvRead(id, read);
  };

  const [showTagsPopup, setShowTagsPopup] = useState(false);

  const openTagsPopup = () => {
    setShowTagsPopup(true);
  };

  const closeTagsPopup = () => {
    setShowTagsPopup(false);
  };

  function TruncatedText({ text, maxLength }) {
    const displayText =
      text.length > maxLength ? text.slice(0, maxLength) + "..." : text;

    return <div>{displayText}</div>;
  }
  function CustomTooltip({ children, text }) {
    const [isVisible, setIsVisible] = useState(false);

    return (
      <div
        className="custom-tooltip"
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
        {isVisible && <div className="tooltip-content">{text}</div>}
      </div>
    );
  }
  function DateCreation({ date_created }) {
    // Calculate the date 3 months ago
    const threeMonthsAgo = moment().subtract(3, "months");

    // Check if date_created is older than 3 months
    const isOld = moment(date_created).isBefore(threeMonthsAgo);

    // Set class name based on the comparison
    const className = isOld ? "old-cree-le" : "cree-le";

    return (
      <div className={className}>
        {moment(date_created).format("DD/MM/YYYY")}
      </div>
    );
  }
  function countOccurrences(text, keyword) {
    if (text && keyword) {
      const regex = new RegExp(keyword, "gi");
      const matches = text.match(regex);
      return matches ? matches.length : 0;
    }
  }
  function toggleSaved(e) {
    e.stopPropagation();
    setCvSaved(id, !saved);
  }
  function onClickDelete(e) {
    e.stopPropagation();
    if (
      window.confirm(
        "Souhaitez-vous vraiment supprimer ce CV et ajouter son email à la liste noire ?",
      )
    ) {
      deleteCv(id);
    }
  }
  return (
    <div
      key={id}
      className={cn("item", selected && "item-selected")}
      onClick={onClickItem}
    >
      <div className="job-name">
        <div className="job">
          <TruncatedText text={job} maxLength={15} />
        </div>
        <div className="name">
          <span>- {first_name ? first_name : " "}</span>
          <span>{last_name ? last_name : " "}</span>
        </div>
        <div className={read ? "lu" : "no-lu"}>
          {read ? "Lu" : "Non lu"}
          &nbsp;
          <FontAwesomeIcon icon={read ? faEye : faEyeSlash} />
        </div>
      </div>
      <div className="email-location-source">
        <div className="email">
          <FontAwesomeIcon icon={faAt} />
          &nbsp;{email ? email : " "}
        </div>
        <div className="phone">
          <FontAwesomeIcon icon={faPhone} /> &nbsp;
          {phone_number ? phone_number : " "}
        </div>
        <CustomTooltip text={source}>
          <div className="source">Source du profil</div>
        </CustomTooltip>
        <div className="tags">
          {key_words.length > 0 &&
            key_words
              .slice(0, 4)
              .map((keyword, index) => (
                <Tag
                  key={index}
                  highlight={index}
                  content={`${
                    keyword.charAt(0).toUpperCase() + keyword.slice(1)
                  }`}
                  contentOccurences={`${countOccurrences(content, keyword)}`}
                />
              ))}
          {key_words.length > 4 && (
            <span
              className="more-tags"
              onMouseEnter={openTagsPopup}
              onMouseLeave={closeTagsPopup}
            >
              ...
              {showTagsPopup && (
                <div className="tags-popup">
                  {key_words.slice(4).map((keyword, index) => (
                    <Tag
                      key={index}
                      highlight={index + 4}
                      content={`${
                        keyword.charAt(0).toUpperCase() + keyword.slice(1)
                      }`}
                      contentOccurences={`${countOccurrences(
                        content,
                        keyword,
                      )}`}
                    />
                  ))}
                </div>
              )}
            </span>
          )}
        </div>
      </div>
      <div className="date-type-button">
        <div
          className={cn(
            "type-contrat",
            application_type === "FREELANCE" ? "freelance" : "cdi",
          )}
        >
          {application_type === "FREELANCE" ? "Freelance" : application_type}
        </div>
        <DateCreation date_created={date_created} />
        <VisuCvBouton path_pdf={path_pdf} token={token} />
        <div className="users">
          {users?.length > 0 && (
            <>
              <div className="aussi-par">Cv enregistré par</div>
              <div>
                {users.map((user) => (
                  <div id="savedby">{user.name}</div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="star">
        <div
          className={cn("save", saved && "saved")}
          onClick={toggleSaved}
          title={saved ? "Désenregistrer le cv" : "Enregistrer le cv"}
        >
          <FontAwesomeIcon icon={saved ? faBookmark : faStarVide} />
        </div>
        <div
          className="delete-cv"
          onClick={onClickDelete}
          title="Supprimer le CV."
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import "./BarreUser.css";

const BarreUser = ({ isAuthorized, searchUsers, handleSearchUsers, token }) => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Récupére les valeurs des champs du formulaire
    const email = document.getElementById("email").value;
    const name = document.getElementById("prenom").value;
    const lastName = document.getElementById("nom").value;
    const roleValue = document.getElementById("role").value;
    const password = document.getElementById("password").value;
    const confirmPassword = document.getElementById("confirm-password").value;
    const id_boond = document.getElementById("id_boond").value;

    if (
      !email ||
      !name ||
      !lastName ||
      !roleValue ||
      !password ||
      !confirmPassword
    ) {
      alert("Veuillez remplir tous les champs.");
      return;
    }
    if (password !== confirmPassword) {
      alert("Les mots de passe ne correspondent pas.");
      return;
    }

    const role = roleValue === "administrateur";

    // Crée l'objet utilisateur à envoyer
    const newUser = {
      email: email,
      name: name,
      last_name: lastName,
      role: role,
      password: password,
      cv_ids: [],
      fav_search: [],
      cv_read: [],
      id_boond: id_boond,
    };

    // Envoie la requête POST à l'endpoint backend
    fetch("/api/users", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(newUser),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error creating user");
        }
        return response.json();
      })
      .then((data) => {
        alert("Utilisateur créé avec succès!");
      })
      .catch((error) => {
        console.error("Error creating user:", error);
      });
  };

  return (
    <div id="barreUsers">
      <div id="user-recherche">
        <input
          className="hi-barrerecherche"
          type="text"
          placeholder="Ex : Prenom.Nom@visian.fr"
          value={searchUsers}
          onChange={handleSearchUsers}
          size="89"
        />
      </div>
      {isAuthorized && (
        <div className="add-delete-button-container">
          <button
            type="submit"
            className="add_button_user"
            onClick={togglePopup}
          >
            Ajouter
          </button>
        </div>
      )}
      {showPopup && (
        <div className="popup">
          <form onSubmit={handleSubmit}>
            <div className="form-user-container">
              <div className="title-container-add-user">
                Ajouter Utilisateur
              </div>
              <div className="label-container">
                <div className="add-user-identity">
                  <label htmlFor="nom">Nom*</label>
                  <input type="text" id="nom" name="nom" />

                  <label htmlFor="prenom">Prenom*</label>
                  <input type="text" id="prenom" name="prenom" />

                  <label htmlFor="email">Email*</label>
                  <input type="email" id="email" name="email" />
                </div>
                <div className="add-user-pwd">
                  <label htmlFor="role">Role*</label>
                  <select id="role" name="role">
                    <option value="utilisateur">Utilisateur</option>
                    <option value="administrateur">Administrateur</option>
                  </select>

                  <label htmlFor="password">Mot de passe*</label>
                  <input type="password" id="password" name="password" />

                  <label htmlFor="confirm-password">
                    Confirmer le mot de passe*
                  </label>
                  <input
                    type="password"
                    id="confirm-password"
                    name="confirm-password"
                  />
                </div>
                <div className="save-form-user">
                  <label htmlFor="id_boond">id Boond</label>
                  <input type="id_boond" id="id_boond" name="id_boond" />
                  <button className="save-form-user-button" type="submit">
                    Enregistrer
                  </button>
                </div>
              </div>
            </div>
          </form>
          <button className="close-form-user" onClick={togglePopup}>
            Fermer
          </button>
        </div>
      )}
    </div>
  );
};

export default BarreUser;

import React, { useEffect } from "react";
import "./navigation.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faAnglesLeft,
  faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";

export default function Navigation({
  page,
  currentTotalCvs,
  maxPage,
  loadNextPage,
  loadPreviousPage,
  loadLastPage,
  loadFirstPage,
  setWishPage,
}) {
  // Function to render pagination buttons with ellipses
  const renderPagination = () => {
    let pages = [];
    let startPage, endPage;

    if (maxPage <= 5) {
      // less than 5 total pages so show all
      startPage = 1;
      endPage = maxPage;
    } else {
      // more than 5 total pages so calculate start and end pages
      if (page <= 3) {
        startPage = 1;
        endPage = 3;
      } else if (page + 2 >= maxPage) {
        startPage = maxPage - 4;
        endPage = maxPage;
      } else {
        startPage = page - 2;
        endPage = page + 2;
      }
    }

    // Add the first page and ellipsis if necessary
    if (startPage > 1) {
      pages.push(
        <button id="button_navigation" key={1} onClick={() => setWishPage(1)}>
          1
        </button>,
      );
      pages.push(<span key="ellipsis1">...</span>);
    }

    // Add the page numbers
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          id="button_navigation"
          key={i}
          onClick={() => setWishPage(i)}
          disabled={i === page}
        >
          {i}
        </button>,
      );
    }

    // Add the last page and ellipsis if necessary
    if (endPage < maxPage) {
      pages.push(<span key="ellipsis2">...</span>);
      pages.push(
        <button
          id="button_navigation"
          key={maxPage}
          onClick={() => setWishPage(maxPage)}
        >
          {maxPage}
        </button>,
      );
    }

    return pages;
  };
  return (
    <div id="navigation">
      <form id="wish-part">
        <select
          value={page}
          onChange={(e) => {
            setWishPage(e.target.value);
            document.getElementById("wish-part").requestSubmit();
          }}
        >
          {[...Array(maxPage).keys()].map((page) => (
            <option key={page} value={page + 1}>
              {page + 1}
            </option>
          ))}
        </select>
        <span id="wish-part-text">
          sur <strong>{currentTotalCvs}</strong> résultats
        </span>
      </form>
      <div id="fleches-part">
        <button
          id="button_navigation"
          onClick={loadFirstPage}
          style={{ visibility: page > 1 ? "visible" : "hidden" }}
        >
          <FontAwesomeIcon icon={faAnglesLeft} />
        </button>
        <button
          id="button_navigation"
          onClick={loadPreviousPage}
          style={{ visibility: page > 1 ? "visible" : "hidden" }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        {/* Pagination Buttons */}
        {renderPagination()}

        <button
          id="button_navigation"
          onClick={loadNextPage}
          style={{ visibility: page < maxPage ? "visible" : "hidden" }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
        <button
          id="button_navigation"
          onClick={loadLastPage}
          style={{ visibility: page < maxPage ? "visible" : "hidden" }}
        >
          <FontAwesomeIcon icon={faAnglesRight} />
        </button>
      </div>
    </div>
  );
}

import { useEffect, useState } from "react";
import Login from "./Components/login/Login";
import Main2 from "./Components/main/Main2";

const App = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState(null);
  const [checkTokenState, setCheckTokenState] = useState({
    loading: false,
    error: "",
    valid: false,
  });

  useEffect(() => {
    const user = async () => {
      const res = await fetch("/api/users/me", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!res.ok) {
        setToken(null);
        if (res.status == 401) {
          setCheckTokenState({
            loading: false,
            error: "La session a expiré.",
            valid: false,
          });
        } else {
          setCheckTokenState({
            loading: false,
            error: `Erreur inconnue, code = ${res.status}.`,
            valid: false,
          });
        }
        return;
      } else {
        const user = await res.json();
        setUser(user);
        return user;
      }
    };

    if (token) {
      user();
    } else {
      setUser(null);
    }
  }, [token]);

  if (!token && !user) {
    return (
      <div className="columns">
        <Login setToken={setToken} inputError={checkTokenState.error} />
      </div>
    );
  } else if (user) {
    return <Main2 token={token} setToken={setToken} user={user} />;
  }
};

export default App;

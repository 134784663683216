import React, { useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileLines,
  faBookmark,
} from "@fortawesome/free-solid-svg-icons";
import "./tabHistorique.css";

const TabHistorique = ({handleMenuHistoChange, menuStateHisto}) => {
    const tabsRef = useRef();
    const barreRef = useRef();
    const onTabClick = (e) => {
        const tabId = e.currentTarget.id;
        
        handleMenuHistoChange(parseInt(tabId, 10));
        // Supprimez la classe "active" de tous les onglets
        tabsRef.current.querySelectorAll(".tab").forEach((tab) => {
            tab.classList.remove("active");
        });
    
        // Ajoutez la classe "active" à l'onglet cliqué
        e.currentTarget.classList.add("active");
    
        // Appelez la fonction pour placer la barre de soulignement
        placeBarre();
    };
    const placeBarre = () => {
        if (tabsRef.current == null) return;
    
        const active = tabsRef.current.querySelector(".active");
    
        barreRef.current.style.left = active.offsetLeft + "px";
        barreRef.current.style.top = active.offsetTop + active.offsetHeight + "px";
        barreRef.current.style.width = active.offsetWidth + "px";
    };
    useEffect(() => {
        addListeners();
        return removeListeners;
    }, [placeBarre]);
    
    let ro;
    
    const addListeners = () => {
        ro = new ResizeObserver(placeBarre);
        ro.observe(tabsRef.current);
    };
    
    const removeListeners = () => {
        ro.disconnect();
    };
    return (
        <div id="hi-tabs" ref={tabsRef}>
            <div id="0" className="tab active" onClick={onTabClick}>
                <span className="spanIcon">
                <FontAwesomeIcon icon={faFileLines} className="tab-icon" />
                </span>
                &nbsp; <b>Les dernières recherches CV</b>
            </div>
            <div id="1" className="tab" onClick={onTabClick}>
                <span className="spanIcon">
                <FontAwesomeIcon icon={faBookmark} className="tab-icon" />
                </span>
                &nbsp; <b>Mes recherches en favoris</b>
            </div>
            <div id="barre" ref={barreRef}></div>
        </div>
   );
};

export default TabHistorique;
import React, { createRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileLines,
  faUsers,
  faBookmark,
} from "@fortawesome/free-solid-svg-icons";
import "./tabs.css";
export default function Tabs({
  tabChanged,
  nbCvsTotal,
  nbSavedCvs,
  nbGroupSavedCvs,
}) {
  let tabsRef = createRef();
  let barreRef = createRef();
  function onTabClick(e) {
    const tabId = e.currentTarget.id; // Use currentTarget here
    tabChanged(Number(tabId));
    tabsRef.current.querySelectorAll(".tab").forEach((tab) => {
      tab.classList.remove("active");
    });
    e.currentTarget.classList.add("active"); // And also here
    placeBarre();
  }
  function placeBarre() {
    if (tabsRef.current == null) return;
    const active = tabsRef.current.querySelector(".active");
    barreRef.current.style.left = active.offsetLeft + "px";
    barreRef.current.style.top = active.offsetTop + active.offsetHeight + "px";
    barreRef.current.style.width = active.offsetWidth + "px";
  }
  useEffect(() => {
    addListeners();
    return removeListeners;
  }, [placeBarre]);
  let ro;
  function addListeners() {
    ro = new ResizeObserver(placeBarre);
    ro.observe(tabsRef.current);
  }
  function removeListeners() {
    ro.disconnect();
  }
  /* useEffect(() => {
        placeBarre()
    }, []); */
  return (
    <div id="tabs" ref={tabsRef}>
      <div id="0" className="tab active" onClick={onTabClick}>
        <span className="spanIcon">
          <FontAwesomeIcon icon={faFileLines} className="tab-icon" />
        </span>
        &nbsp; <b>Cvthèque</b> ({nbCvsTotal})
      </div>
      <div id="1" className="tab" onClick={onTabClick}>
        <span className="spanIcon">
          <FontAwesomeIcon icon={faBookmark} className="tab-icon" />
        </span>
        &nbsp; <b>Mes CVs</b>({nbSavedCvs})
      </div>
      <div id="2" className="tab" onClick={onTabClick}>
        <span className="spanIcon">
          <FontAwesomeIcon icon={faUsers} className="tab-icon" />
        </span>
        &nbsp; <b>CVs équipe</b> ({nbGroupSavedCvs})
      </div>
      <div id="barre" ref={barreRef}></div>
    </div>
  );
}

import React from 'react'
import { cn } from '../../utils/utils'
import './splitter.css'

const Splitter = ({
	id = 'splitter',
	dir,
	isDragging,
	...props
}) => {
	return (
		<div
			id={id}
			data-testid={id}
			tabIndex={0}
			className={cn(
				'sample-drag-bar',
				dir === 'horizontal' && 'sample-drag-bar--horizontal',
				isDragging && 'sample-drag-bar--dragging'
			)}
			{...props}
		/>
	)
}
export default Splitter

import logo from "../logo.png";
import React, { useState } from "react";
import PropTypes from "prop-types";
import "./login.css";

const Login = ({ setToken, inputError }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  async function handleSubmit(e) {
    e.preventDefault();
    const token = await loginUser({
      grant_type: "",
      username: username.toLowerCase(),
      password,
      scope: "",
      client_id: "",
      client_secret: "",
    });
    if (token.detail) {
      setErrorMessage(token.detail);
    } else {
      setToken(token.access_token);
    }
  }
  return (
    <form onSubmit={handleSubmit} id="carre-page">
      <img id="logo-visian" src={logo} alt="logo visian" />
      <div id="partie-welcome">
        <div id="welcome">Welcome back!</div>
        <div id="pas-de-compte" href="mailto:Yohan.Gombert@visian.fr">
          Pas encore de compte ? <a id="contacter-yohan">Contacter Yohan</a>
        </div>
      </div>
      <div id="error-message">
        {errorMessage
          ? typeof errorMessage === "string"
            ? errorMessage
            : errorMessage.msg
          : typeof inputError === "string"
            ? inputError
            : inputError.msg}
      </div>
      <div id="fields">
        <label htmlFor="email">Email:</label>
        <input
          id="email"
          placeholder="Adresse email"
          onChange={(e) => setUsername(e.target.value)}
          autoComplete="email"
        />
        <label htmlFor="mdp">Mot de passe:</label>
        <input
          id="mdp"
          type="password"
          placeholder="Mot de passe"
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="current-password"
        />
        <div id="mdp-container">
          <a id="mdp-oublie">Mot de passe oublié ?</a>
        </div>
      </div>
      <button id="se-connecter" type="submit">
        Se connecter
      </button>
    </form>
  );
};

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};

async function loginUser(credentials) {
  return fetch("/api/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: "'" + new URLSearchParams(credentials).toString() + "'",
  })
    .then((res) => res.json())
    .then((data) => {
      localStorage.setItem("token", data.access_token);
      return data;
    })
    .catch((data) => {
      console.error("ERREUR authentification : ", data);
    });
}

export default Login;

import { SelectButton } from "primereact/selectbutton";
import React, { useEffect, useState } from "react";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import "./monitoringPage.css";

const MonitoringPage = ({ users, token }) => {
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showLogButtons, setShowLogButtons] = useState(false);
  const [showLogUserButtons, setShowLogUserButtons] = useState(false);
  const [showLogLocationButtons, setShowLogLocationButtons] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedLogType, setSelectedLogType] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    fetchData();
  }, [selectedDate, selectedUser, selectedLocation]);

  const fetchData = async () => {
    try {
      let url = "/api/extract/jsonlogs";
      const params = [];
      if (selectedDate) {
        params.push(`date=${selectedDate}`);
      }
      if (selectedUser) {
        params.push(`sender=${selectedUser}`);
      }
      if (selectedLocation) {
        params.push(`location=${selectedLocation}`);
      }
      if (params.length > 0) {
        url += `?${params.join("&")}`;
      }
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error("Error fetching json data:", error);
    }
  };

  const filterDataByDate = (date) => {
    setSelectedDate(date);
  };

  const handleUserChange = (e) => {
    setSelectedUser(e.value);
  };

  const handleLocationChange = (e) => {
    setSelectedLocation(e.value);
  };

  const locationOptions = [
    { label: "Paris", value: "paris" },
    { label: "Marseille", value: "marseille" },
  ];

  const logOptions = [
    { label: "Logs du mois", value: "MONTH" },
    { label: "Logs de la semaine", value: "WEEK" },
    { label: "Logs du jour", value: "DAY" },
  ];

  const handleLogTypeChange = (e) => {
    const selectedLogType = e.value;
    setSelectedLogType(selectedLogType);
    if (selectedLogType === "MONTH") {
      handleThisMonthsLogs();
    } else if (selectedLogType === "WEEK") {
      handleThisWeeksLogs();
    } else if (selectedLogType === "DAY") {
      handleThisDayLogs();
    }
  };

  const sourceNames = {
    1: "Turnover",
    2: "Linkedin",
    3: "Boond",
    4: "CVtech++",
  };

  const groupDataBySource = () => {
    const groupedData = {};
    data.forEach((item) => {
      if (!groupedData[item.source]) {
        groupedData[item.source] = { SUCCESS: 0, ERROR: 0 };
      }
      groupedData[item.source][item.code_status]++;
    });
    return groupedData;
  };

  const renderPieCharts = () => {
    const groupedData = groupDataBySource();
    return Object.entries(groupedData).map(([source, { SUCCESS, ERROR }]) => {
      const total = SUCCESS + ERROR;
      return (
        <div key={source} className={`graph-container source-${source}`}>
          <h3>
            Source {source}: {sourceNames[source]}
          </h3>
          <p className="graph-title">Total: {total}</p>
          <PieChart width={400} height={300}>
            <Pie
              data={[
                { name: "Success", value: SUCCESS },
                { name: "Error", value: ERROR },
              ]}
              cx={200}
              cy={150}
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <Cell fill="#82ca9d" />
              <Cell fill="#ff5500" />
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </div>
      );
    });
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const renderLogButtons = () => {
    if (showLogButtons) {
      return (
        <div className="log-options">
          <div className="date-filter">
            <SelectButton
              value={selectedLogType}
              options={logOptions}
              onChange={handleLogTypeChange}
              placeholder="Sélectionner une période"
              className="active-button"
            />
          </div>
        </div>
      );
    }
    return null;
  };

  const renderUserButton = () => {
    if (showLogUserButtons) {
      return (
        <div className="log-user-options">
          <div className="user-filter">
            <SelectButton
              value={selectedUser}
              options={users.map((user) => ({
                label: `${user.email.split("@")[0]}`,
                value: user.email,
              }))}
              onChange={handleUserChange}
              placeholder="Sélectionner un utilisateur"
              className="active-button"
            />
          </div>
        </div>
      );
    }
  };

  const renderLocationButton = () => {
    if (showLogLocationButtons) {
      return (
        <div className="log-location-options">
          <div className="location-filter">
            <SelectButton
              value={selectedLocation}
              options={locationOptions}
              onChange={handleLocationChange}
              placeholder="Sélectionner une localisation"
              className="active-button"
            />
          </div>
        </div>
      );
    }
  };

  const handleToggleLogButtons = () => {
    setShowLogButtons(!showLogButtons);
  };
  const handleToggleLogUserButtons = () => {
    setShowLogUserButtons(!showLogUserButtons);
  };
  const handleToggleLogLocationButtons = () => {
    setShowLogLocationButtons(!showLogLocationButtons);
  };
  const handleResetFilters = () => {
    setSelectedDate(null);
    setSelectedUser(null);
    setSelectedLogType(null);
    setSelectedLocation(null);
    setShowLogButtons(false);
    setShowLogUserButtons(false);
    setShowLogLocationButtons(false);
  };

  const renderDateButton = () => {
    return (
      <button className="date-filter-toggle" onClick={handleToggleLogButtons}>
        Sélectionner une période
      </button>
    );
  };
  const renderUsersButton = () => {
    return (
      <button
        className="user-filter-toggle"
        onClick={handleToggleLogUserButtons}
      >
        Sélectionner un utilisateur
      </button>
    );
  };
  const renderLocationsButton = () => {
    return (
      <button
        className="location-filter-toggle"
        onClick={handleToggleLogLocationButtons}
      >
        Sélectionner une localisation
      </button>
    );
  };

  const handleThisMonthsLogs = () => {
    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1); // Premier jour du mois
    const month = String(firstDayOfMonth.getMonth() + 1).padStart(2, "0"); // Mois actuel
    const formattedFirstDay = `${firstDayOfMonth.getDate()}/${month}/${firstDayOfMonth.getFullYear()}`;
    filterDataByDate(`${formattedFirstDay} 00:00:00`);
  };

  const handleThisWeeksLogs = () => {
    const now = new Date();
    const firstDayOfWeek = new Date(now.setDate(now.getDate() - now.getDay())); // Premier jour de la semaine (dimanche)
    const month = String(firstDayOfWeek.getMonth() + 1).padStart(2, "0"); // Mois actuel
    const formattedFirstDay = `${firstDayOfWeek.getDate()}/${month}/${firstDayOfWeek.getFullYear()}`;
    filterDataByDate(`${formattedFirstDay} 00:00:00`);
  };

  const handleThisDayLogs = () => {
    const now = new Date();
    const startOfDay = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    ); // Début du jour actuel
    const month = String(startOfDay.getMonth() + 1).padStart(2, "0"); // Mois actuel
    const formattedStartOfDay = `${startOfDay.getDate()}/${month}/${startOfDay.getFullYear()}`;
    filterDataByDate(`${formattedStartOfDay} 00:00:00`);
  };

  return (
    <div className="monitoring-container-wrapper">
      <div className="monitoring-container">
        <div className="date-filter">
          <button
            className="monitoring-filter-button"
            onClick={handleResetFilters}
          >
            Tous les logs
          </button>

          {renderDateButton()}
          {renderLogButtons()}

          {renderLocationsButton()}
          {renderLocationButton()}

          {renderUsersButton()}
          {renderUserButton()}
        </div>
        <div className="pie-charts-container">{renderPieCharts()}</div>
      </div>
    </div>
  );
};

export default MonitoringPage;

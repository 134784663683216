import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { AutoComplete } from "primereact/autocomplete";
import { MultiSelect } from "primereact/multiselect";
import "primereact/resources/themes/lara-light-blue/theme.css";
import React, { useEffect, useRef, useState } from "react";
import "./barreRecherche.css";
import eye from "./eye.png";
import listeSuggestion from "./listeSuggestion.json";

export default function BarreRecherche({
  search,
  onChangeValSearch,
  resetFilters,
  selectedType,
  onChangeApplicationType2,
  selectedLocation,
  onChangeApplicationLocation2,
  selectedDate,
  onChangeApplicationDate2,
  selectedSource,
  onChangeApplicationSource2,
  readItem,
  onChangeReadItem,
  recherchesHisto,
  criteria,
}) {
  const CheckRefRead = useRef();
  //prime react
  const getPlaceholderType = () => {
    const count = selectedType.length;
    if (count === 0) return "Status";
    return (
      <span className="LabelSelectedItem">
        {count > 1 ? "Status" : "Status"}
        <span className="numberOfSelected">{count}</span>
      </span>
    );
  };
  const getPlaceholderLocation = () => {
    const count = selectedLocation.length;
    if (count === 0) return "Localisation";
    return (
      <span className="LabelSelectedItem">
        {count > 1 ? "Villes" : "Ville"}
        <span className="numberOfSelected">{count}</span>
      </span>
    );
  };
  const getPlaceholderDate = () => {
    return <span className="LabelSelectedItem">Date</span>;
  };
  const getPlaceholderSource = () => {
    const count = selectedSource.length;
    if (count === 0) return "Source";
    return (
      <span className="LabelSelectedItem">
        {count > 1 ? "Source" : "Source"}
        <span className="numberOfSelected">{count}</span>
      </span>
    );
  };
  const types = [
    { name: "CDI", code: "CDI" },
    { name: "Freelance", code: "FREELANCE" },
  ];
  const locations = [
    { name: "Paris", code: "PARIS" },
    { name: "Marseille", code: "MARSEILLE" },
  ];
  const dates = [
    { name: "Les dernières 24 heures", code: "today" },
    {
      name: "Cette semaine",
      code: "start_week",
    },
    {
      name: "La semaine dernière",
      code: "last_week",
    },
    {
      name: "1 mois",
      code: "1_month",
    },
    {
      name: "2 mois",
      code: "2_month",
    },
    {
      name: "3 mois",
      code: "3_month",
    },
    {
      name: "4 mois",
      code: "4_month",
    },
  ];
  const sources = [
    { name: "Linkedin", code: "LINKEDIN-VASKA" },
    { name: "Turnover", code: "TURNOVER" },
    { name: "Boond", code: "BOOND" },
    { name: "Sharepoint", code: "SHAREPOINT" },
    { name: "Extension", code: "CVTECH++" },
  ];

  useEffect(() => {
    setSearchValue(criteria); // Met à jour searchValue avec la valeur de criteria
  }, [criteria]);
  //auto complete
  const [suggestions, setSuggestions] = useState(listeSuggestion);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const combinedSuggestions = [
    ...suggestions,
    ...recherchesHisto.map((recherche) => ({
      name: recherche.critere_recherche,
    })),
  ];
  const uniqueSuggestionsSet = new Set();

  // Filtre les doublons et ajoute les suggestions uniques à l'ensemble
  combinedSuggestions.forEach((suggestion) => {
    if (suggestion && suggestion.name) {
      uniqueSuggestionsSet.add(suggestion.name.toLowerCase());
    }
  });

  // Converti l'ensemble en tableau pour obtenir combinedSuggestionsFiltered
  const combinedSuggestionsFiltered = Array.from(uniqueSuggestionsSet).map(
    (name) => ({ name }),
  );

  const searchSuggestions = (event) => {
    const query = event.query.trim();
    const lastToken = query.split(/(?:\s+|and|et|&|\(|\))+/).pop();
    const filteredSuggestions = combinedSuggestionsFiltered
      .filter(
        (suggestion) =>
          suggestion &&
          suggestion.name &&
          suggestion.name.toLowerCase().includes(lastToken.toLowerCase()),
      )
      .map((suggestion) => {
        let truncatedName = suggestion.name;
        if (truncatedName.length > 50) {
          truncatedName = truncatedName.substring(0, 50) + "...";
        }
        const prefix = query.slice(0, -lastToken.length); // Text before the last token
        return prefix + truncatedName; // Concatenate prefix and suggestion
      });
    setFilteredSuggestions(filteredSuggestions);
  };

  const handleSelectSuggestion = (event) => {
    const selectedValue = event.value;
    const tokens = searchValue.split(/(?:\s+|and|et|&)+/);

    // Trouve le préfixe commun entre la saisie manuelle et la suggestion
    let commonPrefix = "";
    for (
      let i = 0;
      i < Math.min(tokens.length, selectedValue.split(" ").length);
      i++
    ) {
      if (
        tokens[i].toLowerCase() === selectedValue.split(" ")[i].toLowerCase()
      ) {
        commonPrefix += tokens[i] + " ";
      } else {
        break;
      }
    }

    // Ajoute la partie manquante de la suggestion
    const suffixToAdd = selectedValue.slice(commonPrefix.length);
    const updatedValue = `${commonPrefix}${suffixToAdd} `;
    setSearchValue(updatedValue);
    onChangeValSearch({ target: { value: updatedValue } });

    setFilteredSuggestions([]); // ferme la liste déroulante après la sélection
  };

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
    onChangeValSearch(e);
  };
  const handleReset = () => {
    resetFilters();
    setSearchValue("");
    onChangeValSearch({ target: { value: "" } });
  };

  function onSubmit(e) {
    e.preventDefault();
    search();
  }
  useEffect(() => {
    CheckRefRead.current.checked = readItem;
  }, [CheckRefRead]);
  return (
    <form id="sb-container" onSubmit={onSubmit}>
      <div className="saisie">
        <div className="search-icon-container">
          <FontAwesomeIcon icon={faMagnifyingGlass} className="search-icon" />
        </div>

        <AutoComplete
          value={searchValue}
          suggestions={filteredSuggestions}
          completeMethod={searchSuggestions}
          onChange={handleInputChange}
          onSelect={handleSelectSuggestion}
          placeholder="Ex : ('Product Owner' AND Javascript) OR ReactJS"
          size={58}
          itemTemplate={(item) => (
            <div style={{ marginLeft: "1.8rem" }}>{item}</div>
          )}
        />

        <button type="submit" className="search-button">
          Recherche
        </button>
        <button
          className="reinitialiser"
          onClick={() => {
            resetFilters();
            handleReset();
          }}
        >
          Réinitialiser
        </button>
      </div>
      <div className="form-multi-select">
        <MultiSelect
          id="application-type"
          value={selectedType}
          options={types}
          onChange={onChangeApplicationType2}
          optionLabel="name"
          maxSelectedLabels={1}
          placeholder={getPlaceholderType()}
          className={getPlaceholderType() !== "Status" ? "custom-white" : null}
          selectedItemsLabel={getPlaceholderType()}
        />
        <MultiSelect
          id="application-location"
          value={selectedLocation}
          options={locations}
          maxSelectedLabels={1}
          onChange={onChangeApplicationLocation2}
          optionLabel="name"
          placeholder="Localisation"
          className={
            getPlaceholderLocation() !== "Localisation" ? "custom-white" : null
          }
          selectedItemsLabel={getPlaceholderLocation()}
        />
        <MultiSelect
          id="application-date"
          value={selectedDate}
          options={dates}
          maxSelectedLabels={1}
          onChange={onChangeApplicationDate2}
          optionLabel="name"
          placeholder="Date"
          className={getPlaceholderDate() !== "Date" ? "custom-white" : null}
          selectedItemsLabel={getPlaceholderDate()}
        />
        <MultiSelect
          id="application-source"
          value={selectedSource}
          options={sources}
          maxSelectedLabels={1}
          onChange={onChangeApplicationSource2}
          optionLabel="name"
          placeholder="Source"
          className={
            getPlaceholderSource() !== "Source" ? "custom-white" : null
          }
          selectedItemsLabel={getPlaceholderSource()}
        />
        <div className="nn-v">
          <input
            className="checkbox"
            type="checkbox"
            id="application-checkbox"
            ref={CheckRefRead}
            onChange={onChangeReadItem}
            checked={readItem}
          />
          <label className="aff-non-vue">Profils "non vue"</label>
          <img className="eye-slash" src={eye} alt="Eye Slash" />
        </div>
      </div>
    </form>
  );
}

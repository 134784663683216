import "./visuCvBouton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

export default function VisuCvBouton({ path_pdf, token }) {
  async function onClick() {
    const hostname = window.location.hostname;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    let cvUrl = "";
    await fetch(
      `https://${hostname}/api/generate-presigned-url?path=${path_pdf}`,
      options
    )
      .then((res) => res.json())
      .then((json) => {
        cvUrl = json.url;
      })
      .catch((res) => {
        console.error(res);
      });
    window.open(cvUrl, "_blank");
  }
  return (
    <button className="visualiser-cv" onClick={onClick} title="Ouvrir le cv">
      <div id="texte-bouton">Ouvrir le cv</div>
      <FontAwesomeIcon icon={faDownload} />
    </button>
  );
}

import React, { useState, useEffect } from "react";
import spinner from "../../spinner.svg";
import VisuCvBouton from "../visu-cv-bouton/VisuCvBouton";
import Tag from "../tag/tag";
import { cn } from "../../utils/utils";
import "./cvContent.css";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  faPhone,
  faAt,
  faIdCard,
  faBookmark,
} from "@fortawesome/free-solid-svg-icons";
import { faClock, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faBookmark as faStarVide } from "@fortawesome/free-regular-svg-icons";

export default function CVContent({
  id,
  first_name,
  last_name,
  date_created,
  job,
  email,
  phone_number,
  path,
  key_words,
  width,
  saved,
  setCvSaved,
  deleteCv,
  closeCv,
  token,
}) {
  const [showTagsPopup, setShowTagsPopup] = useState(false);
  const handleToggleTagsPopup = () => {
    setShowTagsPopup(!showTagsPopup);
  };
  const [numberOccurrences, setNumberOccurrences] = useState({});
  const [searchResumeTextState, setSearchResumeTextState] = useState({
    loading: false,
    error: false,
    data: null,
  });

  function toggleSaved(e) {
    e.stopPropagation();
    setCvSaved(id, !saved);
    closeCv();
  }

  function onClickDelete(e) {
    e.stopPropagation();
    if (
      window.confirm(
        "Souhaitez-vous vraiment supprimer ce CV et ajouter son email à la liste noire ?",
      )
    ) {
      deleteCv(id);
    }
  }

  useEffect(() => {
    setSearchResumeTextState({ ...searchResumeTextState, loading: true });
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + token,
      },
    };
    fetch("/api/resume_text?file_name=" + path, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        let content = highlightWords(data.resume_text.toString(), key_words);
        const newNumberOccurrences = {};
        key_words.forEach((keyword) => {
          newNumberOccurrences[keyword] = countNumberOccurrences(
            content,
            keyword,
          );
        });
        setNumberOccurrences(newNumberOccurrences);
        setSearchResumeTextState({
          loading: false,
          error: false,
          data: content,
        });
      })
      .catch((res) => {
        console.error("ERROR", res);
        setSearchResumeTextState({ loading: false, error: true, data: null });
      });
  }, [id]);

  function highlightWords(resumeText, words) {
    let highlightedText = resumeText;
    words?.forEach((word, index) => {
      const dynamicClass = `highlight-${index}`;
      highlightedText = highlightedText.replace(
        new RegExp(`(${word})`, "gi"),
        `<span class="highlight ${dynamicClass}">$1</span>`,
      );
    });
    return { __html: highlightedText };
  }

  function countNumberOccurrences(text, keyword) {
    const content = text && text.__html;
    if (typeof content === "string") {
      const regex = new RegExp(keyword, "gi");
      const matches = content.match(regex);
      return matches ? matches.length : 0;
    } else {
      console.error("Content is not a string:", content);
      return 0;
    }
  }

  let content;
  if (searchResumeTextState.loading)
    content = <img src={spinner} alt="icône de chargement" />;
  else if (searchResumeTextState.error)
    content = <p>Une erreur est survenue.</p>;
  else if (searchResumeTextState.data) {
    content = (
      <div
        style={{ whiteSpace: "pre-line" }}
        dangerouslySetInnerHTML={searchResumeTextState.data}
      ></div>
    );
  }

  return (
    <div id="cv-page" style={{ width: width }}>
      <div id="cv-page-header">
        <div id="job-name">
          <div id="job">{job}</div>
        </div>
        <div id="email_phone">
          <div id="name">
            <FontAwesomeIcon icon={faIdCard} /> &nbsp;
            {first_name} {last_name}
          </div>
          <div id="email_user">
            <FontAwesomeIcon icon={faAt} /> &nbsp;
            {email}
          </div>
          <div id="phone">
            <FontAwesomeIcon icon={faPhone} /> &nbsp;{phone_number}
          </div>
          <div id="date">
            <FontAwesomeIcon icon={faClock} />
            &nbsp;{moment(date_created).format("DD/MM/YYYY")}
          </div>
        </div>
        <button id="close-cv" onClick={closeCv}>
          <FontAwesomeIcon icon={faXmark} />
        </button>

        <div id="visu-cv-container">
          <VisuCvBouton path_pdf={path} token={token} />
        </div>

        <div id="fav">
          <div
            className={cn("save", saved && "saved")}
            onClick={toggleSaved}
            title={saved ? "Désenregistrer le cv" : "Enregistrer le cv"}
          >
            <FontAwesomeIcon icon={saved ? faBookmark : faStarVide} />
          </div>
          <div
            className="delete-cv"
            onClick={onClickDelete}
            title="Supprimer le CV."
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </div>
        </div>

        <button className="display-tags" onClick={handleToggleTagsPopup}>
          Afficher les Tags
        </button>

        {showTagsPopup && (
          <div className="tags-popup">
            <div className="tags-popup-content">
              {key_words.map((w, i) => (
                <Tag
                  key={w}
                  highlight={i}
                  content={w}
                  contentOccurences={numberOccurrences[w]}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <div id="cv-content">{content}</div>
    </div>
  );
}

import React, { useState } from "react";
import "./userContent.css";
import UserElement from "./UserElement";

const UserContent = ({ filteredUsers, isAuthorized, deleteUser }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 7;

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };


    return (
        <div id="users">
            <div className="users-entete">
                <label htmlFor="email" className="la-user-email">Email</label>
                <label htmlFor="prenom" className="la-user-firstname">Prenom</label>
                <label htmlFor="nom" className="la-user-lastname">Nom</label>
                <label htmlFor="role" className="la-user-role">Role</label>
                <label htmlFor="action" className="la-user-action">Action</label>
            </div>
            <div className="users-list">
                {currentUsers.map((user) => (
                    <UserElement  user={user} deleteUser={deleteUser} isAuthorized={isAuthorized}/>
                ))}
            </div>
            <div className="pagination">
                <button className="other-page" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>Précédent</button>
                <span>{currentPage}/{totalPages}</span>
                <button className="other-page" disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>Suivant</button>
            </div>
            
        </div>
    );
};
export default UserContent;
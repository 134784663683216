import Popup from "../popupVerif/PopupVerif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const UserElement = ({ user, deleteUser, isAuthorized }) => {
  const [showPopup, setShowPopup] = useState(false);

  const extractPrenom = (email) => {
    const emailParts = email.split("@")[0].split(".");
    const prenom = emailParts[0];
    return prenom;
  };

  const extractNom = (email) => {
    const emailParts = email.split("@")[0].split(".");
    const nom = emailParts[1];
    return nom;
  };

  const handleDeleteClick = () => {
    setShowPopup(false);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  return (
    <div className="user-item">
      <p className="p-user-email">{user.email}</p>
      <p className="p-user-firstname">{extractPrenom(user.email)}</p>
      <p className="p-user-lastname">{extractNom(user.email)}</p>
      <p className="p-user-role">
        {user.role ? "Administrateur" : "Utilisateur"}
      </p>
      <div className="button-container">
        {isAuthorized && (
          <FontAwesomeIcon
            icon={faTrash}
            className="icon-delete-user"
            onClick={togglePopup}
          />
        )}
        {showPopup && (
          <Popup
            onDelete={handleDeleteClick}
            onClose={togglePopup}
            deleteUser={() => deleteUser(user.email)}
            user={user}
          />
        )}
      </div>
    </div>
  );
};

export default UserElement;
